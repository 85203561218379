import { isString } from '../../utils/guards';

export type IntlASTMessageEntry = {
  type: number;
  value: string;
};

export type IntlASTMessage = IntlASTMessageEntry[];

export type IntlMessages = Record<string, IntlASTMessage>;

type DynamicImport = Promise<{
  default: IntlMessages;
}>;

export type Locale =
  | 'bg-BG'
  | 'cs-CZ'
  | 'da-DK'
  | 'de-AT'
  | 'de-CH'
  | 'de-DE'
  | 'de-LU'
  | 'el-CY'
  | 'el-GR'
  | 'en-AU'
  | 'en-GB'
  | 'en-HK'
  | 'en-IE'
  | 'en-MT'
  | 'en-NZ'
  | 'en-SG'
  | 'en-US'
  | 'es-CL'
  | 'es-CO'
  | 'es-ES'
  | 'es-MX'
  | 'es-PE'
  | 'et-EE'
  | 'fi-FI'
  | 'fr-BE'
  | 'fr-CH'
  | 'fr-FR'
  | 'fr-LU'
  | 'hr-HR'
  | 'hu-HU'
  | 'it-CH'
  | 'it-IT'
  | 'ja-JP'
  | 'lt-LT'
  | 'lv-LV'
  | 'nb-NO'
  | 'nl-BE'
  | 'nl-NL'
  | 'pl-PL'
  | 'pt-BR'
  | 'pt-PT'
  | 'ro-RO'
  | 'sk-SK'
  | 'sl-SI'
  | 'sv-SE';

export type Language =
  | 'bg'
  | 'cs'
  | 'da'
  | 'de'
  | 'el'
  | 'en'
  | 'es'
  | 'et'
  | 'fi'
  | 'fr'
  | 'hr'
  | 'hu'
  | 'it'
  | 'lt'
  | 'lv'
  | 'nb'
  | 'nl'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'sk'
  | 'sl'
  | 'sv';

export type Country =
  | 'AR'
  | 'AT'
  | 'AU'
  | 'BE'
  | 'BG'
  | 'BR'
  | 'CA'
  | 'CH'
  | 'CL'
  | 'CO'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DK'
  | 'EE'
  | 'ES'
  | 'FI'
  | 'FR'
  | 'GB'
  | 'GR'
  | 'HK'
  | 'HU'
  | 'IE'
  | 'IT'
  | 'JP'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'MT'
  | 'MX'
  | 'MY'
  | 'NL'
  | 'NO'
  | 'NZ'
  | 'PE'
  | 'PL'
  | 'PT'
  | 'RO'
  | 'SE'
  | 'SG'
  | 'SI'
  | 'SK'
  | 'US';

export const supportedLocales: Locale[] = [
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-LU',
  'el-CY',
  'el-GR',
  'en-AU',
  'en-GB',
  'en-IE',
  'en-MT',
  'en-US',
  'es-CL',
  'es-CO',
  'es-ES',
  'es-MX',
  'es-PE',
  'et-EE',
  'fi-FI',
  'fr-BE',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'hr-HR',
  'hu-HU',
  'it-CH',
  'it-IT',
  'lt-LT',
  'lv-LV',
  'nb-NO',
  'nl-BE',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'sk-SK',
  'sl-SI',
  'sv-SE',
];

export const DEFAULT_LOCALE: Locale = 'en-GB';

export const supportedCountries: Country[] = [
  'HK',
  'AR',
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CA',
  'CH',
  'CL',
  'CO',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HU',
  'IE',
  'IT',
  'JP',
  'LT',
  'LU',
  'LV',
  'MT',
  'MX',
  'MY',
  'NL',
  'NO',
  'NZ',
  'PE',
  'PL',
  'PT',
  'RO',
  'SE',
  'SG',
  'SI',
  'SK',
  'US',
];

export const supportedCountriesSet = new Set<Country>(supportedCountries);

const localeTranslations: Record<Locale, Locale> = {
  'bg-BG': 'bg-BG',
  'cs-CZ': 'cs-CZ',
  'da-DK': 'da-DK',
  'de-AT': 'de-DE',
  'de-CH': 'de-DE',
  'de-DE': 'de-DE',
  'de-LU': 'de-DE',
  'el-CY': 'el-GR',
  'el-GR': 'el-GR',
  'en-AU': 'en-GB',
  'en-GB': 'en-GB',
  'en-HK': 'en-GB',
  'en-IE': 'en-GB',
  'en-MT': 'en-GB',
  'en-NZ': 'en-GB',
  'en-SG': 'en-GB',
  'en-US': 'en-GB',
  'es-CL': 'es-ES',
  'es-CO': 'es-ES',
  'es-ES': 'es-ES',
  'es-MX': 'es-ES',
  'es-PE': 'es-PE',
  'et-EE': 'et-EE',
  'fi-FI': 'fi-FI',
  'fr-BE': 'fr-FR',
  'fr-CH': 'fr-FR',
  'fr-FR': 'fr-FR',
  'fr-LU': 'fr-FR',
  'hr-HR': 'hr-HR',
  'hu-HU': 'hu-HU',
  'it-CH': 'it-IT',
  'it-IT': 'it-IT',
  'ja-JP': 'ja-JP',
  'lt-LT': 'lt-LT',
  'lv-LV': 'lv-LV',
  'nb-NO': 'nb-NO',
  'nl-BE': 'nl-BE',
  'nl-NL': 'nl-NL',
  'pl-PL': 'pl-PL',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-BR',
  'ro-RO': 'ro-RO',
  'sk-SK': 'sk-SK',
  'sl-SI': 'sl-SI',
  'sv-SE': 'sv-SE',
};

export async function loadTranslations(locale: string): Promise<IntlMessages> {
  let translations: DynamicImport;

  // This switch statement is generated from the locales.js file
  // at the project root using the generate-locale-switch.js script.
  switch (locale.toLowerCase()) {
    case 'bg-bg':
      translations = import('./lang/bg-bg.json');
      break;
    case 'cs-cz':
      translations = import('./lang/cs-cz.json');
      break;
    case 'da-dk':
      translations = import('./lang/da-dk.json');
      break;
    case 'de-at':
      translations = import('./lang/de-at.json');
      break;
    case 'de-ch':
      translations = import('./lang/de-ch.json');
      break;
    case 'de-de':
      translations = import('./lang/de-de.json');
      break;
    case 'de-lu':
      translations = import('./lang/de-lu.json');
      break;
    case 'el-cy':
      translations = import('./lang/el-cy.json');
      break;
    case 'el-gr':
      translations = import('./lang/el-gr.json');
      break;
    case 'en':
      translations = import('./lang/en.json');
      break;
    case 'en-au':
      translations = import('./lang/en-au.json');
      break;
    case 'en-gb':
      translations = import('./lang/en-gb.json');
      break;
    case 'en-ie':
      translations = import('./lang/en-ie.json');
      break;
    case 'en-mt':
      translations = import('./lang/en-mt.json');
      break;
    case 'en-us':
      translations = import('./lang/en-us.json');
      break;
    case 'es-cl':
      translations = import('./lang/es-cl.json');
      break;
    case 'es-co':
      translations = import('./lang/es-co.json');
      break;
    case 'es-es':
      translations = import('./lang/es-es.json');
      break;
    case 'es-mx':
      translations = import('./lang/es-mx.json');
      break;
    case 'es-pe':
      translations = import('./lang/es-pe.json');
      break;
    case 'et-ee':
      translations = import('./lang/et-ee.json');
      break;
    case 'fi-fi':
      translations = import('./lang/fi-fi.json');
      break;
    case 'fr-be':
      translations = import('./lang/fr-be.json');
      break;
    case 'fr-ch':
      translations = import('./lang/fr-ch.json');
      break;
    case 'fr-fr':
      translations = import('./lang/fr-fr.json');
      break;
    case 'fr-lu':
      translations = import('./lang/fr-lu.json');
      break;
    case 'hr-hr':
      translations = import('./lang/hr-hr.json');
      break;
    case 'hu-hu':
      translations = import('./lang/hu-hu.json');
      break;
    case 'it-ch':
      translations = import('./lang/it-ch.json');
      break;
    case 'it-it':
      translations = import('./lang/it-it.json');
      break;
    case 'lt-lt':
      translations = import('./lang/lt-lt.json');
      break;
    case 'lv-lv':
      translations = import('./lang/lv-lv.json');
      break;
    case 'nb-no':
      translations = import('./lang/nb-no.json');
      break;
    case 'nl-be':
      translations = import('./lang/nl-be.json');
      break;
    case 'nl-nl':
      translations = import('./lang/nl-nl.json');
      break;
    case 'pl-pl':
      translations = import('./lang/pl-pl.json');
      break;
    case 'pt-br':
      translations = import('./lang/pt-br.json');
      break;
    case 'pt-pt':
      translations = import('./lang/pt-pt.json');
      break;
    case 'ro-ro':
      translations = import('./lang/ro-ro.json');
      break;
    case 'sk-sk':
      translations = import('./lang/sk-sk.json');
      break;
    case 'sl-si':
      translations = import('./lang/sl-si.json');
      break;
    case 'sv-se':
      translations = import('./lang/sv-se.json');
      break;
    default:
      translations = import('./lang/en.json');
  }

  return translations.then(({ default: def }) => def);
}

export function isLocale(maybeLocale: unknown): maybeLocale is Locale {
  const asLocale = maybeLocale as Locale;
  return supportedLocales.includes(asLocale);
}

export function isCountry(maybeCountry: unknown): maybeCountry is Country {
  if (!isString(maybeCountry)) {
    return false;
  }

  const asCountry = maybeCountry as Country;
  return supportedCountriesSet.has(asCountry);
}

function toISOLocaleFormat(value: string): string {
  const [lang, country = ''] = value.split('-');
  return `${lang.toLowerCase()}-${country.toUpperCase()}`;
}

// TODO: figure out how to specify the default locale exactly once.
export function safeLocale(maybeLocale: unknown): Locale {
  if (typeof maybeLocale !== 'string') {
    return DEFAULT_LOCALE;
  }

  const formatted = toISOLocaleFormat(maybeLocale);

  if (isLocale(formatted)) {
    return formatted;
  }

  return DEFAULT_LOCALE;
}

function getExpiryDate(expiresInDays: number): Date {
  const dayMS = 864e5;
  return new Date(Date.now() + expiresInDays * dayMS);
}

export function createLocaleCookie(
  cookieName: string,
  locale: Locale,
  domain = '',
): string {
  const ttl = 365;

  const cookie = `${cookieName}=${locale}; Path=/; Expires=${getExpiryDate(
    ttl,
  ).toUTCString()}; SameSite=Lax;`;

  if (domain) {
    return `${cookie} Domain=${domain};`;
  }

  return cookie;
}

export function getLocaleCountry(locale: Locale): Country {
  const [, country] = locale.split('-');
  if (!country || !isCountry(country)) {
    return 'GB';
  }

  return country;
}

export function getLanguageLocale(locale: Locale): Locale {
  return localeTranslations[locale] || DEFAULT_LOCALE;
}
